import Vue from 'vue'
import { createStandardPublicClientApplication } from '@azure/msal-browser'

let instance
export const getInstance = () => instance

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)
export const useMsAuth = ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = window.location.origin + '/login', ...options }) => {
    if (instance) return instance

    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                authClient: null,
                error: null
            }
        },
        methods: {
            loginWithRedirect(o) {
                return this.authClient.loginRedirect(o)
            },
            async getTokenSilently() {
                const accessTokenRequest = {
                    scopes: ['user.read'],
                    account: this.user
                }
                const token = await this.authClient.acquireTokenSilent(accessTokenRequest)
                return token.accessToken
            },
            logout() {
                window.Intercom('shutdown')
                return this.authClient.logout({ returnTo: window.location.origin + '/login' })
            }
        },
        async created() {
            this.authClient = await createStandardPublicClientApplication({
                auth: {
                    clientId: process.env.VUE_APP_MSAUTH_CLIENT_ID,
                    authority: process.env.VUE_APP_MSAUTH_AUTHORITY,
                    redirectUri,
                    navigateToLoginRequestUrl: true
                },
                cache: {
                    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
                    storeAuthStateInCookie: false // set this to true if you have to support IE
                }
            })

            try {
                const state = await this.authClient.handleRedirectPromise()
                onRedirectCallback(state)
                this.error = null
            } catch (e) {
                console.log(e)
                this.error = e
            } finally {
                this.isAuthenticated = this.authClient.getAllAccounts().length > 0
                this.user = await this.authClient.getAllAccounts()[0]
                this.loading = false
            }
        }
    })

    return instance
}

export const AuthPlugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useMsAuth(options)
    }
}
