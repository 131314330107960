<template>
    <v-app id="inspire" style="background-color: #fff" ref="app">
        <TheToolbar
            v-if="
                ![
                    'app-open',
                    'login',
                    'signup-index',
                    'signup-home',
                    'signup-options',
                    'signup-provider',
                    'signup-success',
                    'invitation-index',
                    'invitation-password',
                    'invitation-home',
                    'invitation-accept',
                    'invitation-provider',
                    'invitation-authenticated',
                    'trial',
                    'trial-provider'
                ].includes($route.name)
            " />
        <router-view name="permanent-sidebar"></router-view>
        <v-main :style="$route.params.aid !== 'undefined' ? 'overflow-y: hidden' : ''" class="intercom-padding">
            <transition name="fadeonly" mode="out-in">
                <router-view></router-view>
            </transition>
        </v-main>
        <v-snackbar text :value="$store.state['snackBar']" :timeout="-1" elevation="1">
            <div class="d-flex align-center">
                <v-icon class="mr-3" :color="snackBarIcon === 'check_circle' ? 'success' : snackBarIcon === 'error' ? 'error' : 'info'">
                    {{ snackBarIcon }}
                </v-icon>
                <div v-html="snackBarText"></div>
                <v-spacer />
                <v-btn :text="snackBarIcon !== 'cookie'" class="font-weight-bold ml-5" @click="$store.dispatch('hideSnackBar')" color="secondary">
                    {{ snackBarLabel || 'Close' }}
                </v-btn>
            </div>
        </v-snackbar>
        <GlobalDialog />
        <v-snackbar v-model="showUpdatePwa" color="primary" :vertical="true" :timeout="forcePWAUpdate ? 0 : 10000">
            <span v-if="!forcePWAUpdate">A new version of Nuvolos is available. Update?</span>
            <span v-else>Your Nuvolos version requires updating!</span>
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="update">Update</v-btn>
            </template>
        </v-snackbar>
        <div class="shepherd-backdrop"></div>
    </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { parseURL } from './utils'
import regionsMixin from '@/mixins/regions'
import pwa from '@/mixins/pwa'
import { enumsData } from '@/mixins/enums'
import Shepherd from 'shepherd.js'
import { initLogRocketSession, identifySession } from '@/logRocketSession'
import { displayCookiePopup } from '@/cookiePopup'
import { DateTime } from 'luxon'
import { analyticsMixin } from '@/mixins/analytics'

const TheToolbar = () => import('@/components/TheToolbar.vue')
const GlobalDialog = () => import('@/components/GlobalDialog')

export default {
    name: 'app',
    mixins: [regionsMixin, enumsData, pwa, analyticsMixin],
    data() {
        return {
            statusUpdater: null,
            showUsePwaTrigger: true,
            showUpdatePwaTrigger: false,
            tourEvents: ['close', 'cancel', 'complete', 'hide'],
            forcePWAUpdate: false
        }
    },
    methods: {
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height
        },
        enter(element) {
            const { height } = getComputedStyle(element)

            element.style.height = this.prevHeight

            setTimeout(() => {
                element.style.height = height
            })
        },
        afterEnter(element) {
            element.style.height = 'auto'
        },
        dismiss() {
            const date = new Date()
            date.setDate(date.getDate() + 3)
            this.$store.commit('setUserMetadata', { show_install_pwa: date })
            window.deferredPrompt = null
        },
        dismissUsePwa() {
            localStorage.setItem('use-pwa-dismissed', new Date().toISOString())
            this.showUsePwaTrigger = false
        },
        async update() {
            this.showUpdatePwaTrigger = false
            if (this.isPWA()) {
                await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
            } else {
                location.reload()
            }
        }
    },
    created() {
        window.addEventListener('beforeinstallprompt', e => {
            localStorage.removeItem('use-pwa-dismissed')
            e.preventDefault()
            window.deferredPrompt = e
        })
        window.addEventListener('appinstalled', () => {
            window.deferredPrompt = null
        })
        if (this.$workbox) {
            this.$workbox.addEventListener('waiting', () => {
                this.showUpdatePwaTrigger = true
            })
        }
        Shepherd.on(
            'start',
            evt => {
                evt.tour.options.useModalOverlay && this.$refs.app.$el.classList.add('shepherd-active')
            },
            'app'
        )
        this.tourEvents.forEach(event =>
            Shepherd.on(
                event,
                () => {
                    this.$refs.app.$el.classList.remove('shepherd-active')
                },
                'app'
            )
        )
    },
    mounted() {
        window.Intercom('boot', {
            app_id: 'lliic4ws'
        })
        if (displayCookiePopup(this.cookiePopupInterval)) {
            setTimeout(() => {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `<p>Nuvolos stores and uses some data about you in your browser that is required to provide full functionality.</p>`,
                    snackBarIcon: 'cookie',
                    snackBarLabel: `Understood`,
                    snackBarTimeout: 10 * 60 * 1000,
                    snackBarButtonOutline: false
                })
                localStorage.setItem('nvLastPopup', DateTime.now().toISODate())
                sessionStorage.setItem('nvPopupShown', 1)
            }, 3000)
        }
    },
    beforeDestroy() {
        this.tourEvents.forEach(event => Shepherd.off(event, 'app'))
    },
    destroyed() {
        clearInterval(this.statusUpdater)
    },
    computed: {
        isDashboard() {
            return this.$route.name === 'home-dashboard'
        },
        showUpdatePwa() {
            if (this.forcePWAUpdate) {
                return true
            }
            if (!this.isDashboard || !this.showUpdatePwaTrigger || !this.isPWA()) return
            return true
        },
        ...mapGetters('orgStore', ['isOrgManager', 'isOrgFaculty']),
        ...mapState('orgStore', ['fetchingSpaces']),
        ...mapState(['userOrgs', 'userInfo', 'fetchingUserInfo', 'userMetadata']),
        ...mapState('snapshotStore', ['applications']),
        ...mapState(['snackBarText', 'snackBarIcon', 'snackBarLabel']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType']),
        parseCurrentUrl() {
            if (window.location.href.length) {
                return parseURL(window.location.href)
            }
            return null
        },
        fetchingSpaceInstancePrerequisiteLoaded() {
            if (this.$route.params.sid && !this.fetchingSpaces) {
                return this.$route.params.sid
            } else {
                return false
            }
        }
    },
    watch: {
        fetchingSpaceInstancePrerequisiteLoaded: {
            handler() {
                this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
            },
            immediate: true
        },
        '$auth.isAuthenticated': {
            handler(to) {
                if (to) {
                    this.$store.dispatch('getUserInfo')
                }
            },
            immediate: true
        },
        async $route(to, from) {
            if (to.params.snid === undefined && from !== undefined && from.params.snid !== undefined) {
                this.$store.dispatch('snapshotStore/resetSnapshotState')
            }
            if (
                (to.params.iid && from.params.iid && to.params.iid.toString() !== from.params.iid.toString()) ||
                (from.params.iid && to.params.iid === undefined)
            ) {
                this.$store.dispatch('instanceStore/resetInstanceState')
            }
            if (
                (to.params.sid && from.params.sid && to.params.sid.toString() !== from.params.sid.toString()) ||
                (from.params.sid && to.params.sid === undefined)
            ) {
                this.$store.dispatch('spaceStore/resetSpaceState')
            }
            if (to.name === 'home-dashboard') {
                this.$store.dispatch('appStore/fetchRecentApps', this.$route.params.oid)
            }
            if (to.name === 'user-ssh-keys') {
                this.$store.dispatch('userStore/fetchSSHKeys')
            }
            if (
                (to.params.iid && from.params.iid && to.params.iid.toString() !== from.params.iid.toString()) ||
                (to.params.iid && from.params.iid === undefined && to.params.iid !== from.params.iid)
            ) {
                this.$store.dispatch('instanceStore/fetchInstanceSnapshots', to.params.iid)
                this.$store.dispatch('appStore/fetchAllUserApps', { iid: to.params.iid, startFirstApp: false })
                this.$store.dispatch('instanceStore/setSnapshotPollingInterval', to.params.iid)
            }
            if (
                (to.params.sid && from.params.sid && to.params.sid.toString() !== from.params.sid.toString()) ||
                (to.params.sid && from.params.sid === undefined && to.params.sid !== from.params.sid)
            ) {
                this.$store.dispatch('spaceStore/fetchSpaceBundles', to.params.sid)
                this.$store.dispatch('spaceStore/fetchMappedResources', to.params.sid)
                this.$store.dispatch('spaceStore/fetchCurrentSpace')
            }
            if (
                (to.params.oid && from.params.oid && to.params.oid.toString() !== from.params.oid.toString()) ||
                (to.params.oid && from.params.oid === undefined)
            ) {
                const orgData = this.userOrgs.filter(org => org.oid.toString() === to.params.oid.toString())[0]
                this.$store.dispatch('setCurrentOrgData', orgData)
                this.$store.dispatch('orgStore/fetchOrgSpaces', to.params.oid)
                this.$store.dispatch('orgStore/fetchOrgTokens', to.params.oid)
            }
            // hide scrollbar and intercom for apps
            if (to.params.aid !== undefined) {
                window.Intercom('update', {
                    hide_default_launcher: true
                })
            } else {
                window.Intercom('update', {
                    hide_default_launcher: false
                })
            }
            if (to.params.oid) {
                this.$store.dispatch('userStore/fetchDefaultResourcePool', to.params.oid)
            }
        },
        isSpaceAdmin: {
            handler: function (to, from) {
                if (to) {
                    window.Intercom('update', {
                        is_space_admin: true
                    })
                }
            },
            immediate: true
        },
        currentSpaceType: {
            handler: function (to, from) {
                if (to) {
                    if (to === this.spaceTypes.RESEARCH_SPACE) {
                        window.Intercom('update', {
                            has_research_space: true
                        })
                    }
                    if (to === this.spaceTypes.EDUCATION_SPACE) {
                        window.Intercom('update', {
                            has_edu_space: true
                        })
                    }
                }
            },
            immediate: true
        },
        userInfo: {
            handler(to) {
                if (to) {
                    this.$store.dispatch('appStore/updateDeploymentStatus')
                    this.$store.dispatch('fetchUserOrgs')
                    this.$store.dispatch('userStore/fetchAvailableResourcePoolData')

                    clearInterval(this.statusUpdater)
                    this.statusUpdater = setInterval(() => {
                        if (this.$auth.isAuthenticated) {
                            this.$store.dispatch('appStore/updateDeploymentStatus')
                        }
                    }, 10000)

                    window.Intercom('shutdown')
                    if (!this.$route.params.aid && to.hmac) {
                        window.Intercom('boot', {
                            app_id: 'lliic4ws',
                            email: to.email,
                            user_id: to.uid,
                            user_hash: to.hmac,
                            name: to.name || to.given_name + ' ' + to.family_name,
                            user_type: this.isOrgManager ? 'manager' : this.isOrgFaculty ? 'faculty' : 'member',
                            is_trial_user: to.is_trial_user
                        })
                        if (this.isAnalyticsEnabled) {
                            initLogRocketSession()
                            identifySession(to)
                            window.Intercom('update', {
                                has_analytics_data: true
                            })
                        } else {
                            window.Intercom('update', {
                                has_analytics_data: false
                            })
                            window['ga-disable-G-1DK5JS9X22'] = true
                        }
                    }
                }
            },
            immediate: true
        },
        fetchingUserInfo(fetching) {
            if (!fetching && this.userMetadata?.new_user) {
                const date = new Date()
                date.setDate(date.getDate() + 1)
                const usermeta = {
                    show_welcome_tour: true,
                    show_space_tour: true,
                    show_snapshot_restore_guide: true,
                    show_install_pwa: date.toISOString(),
                    seen_grading_tour: false,
                    analytics_opt_in: true
                }
                this.$store.commit('setUserMetadata', usermeta)
            }
        }
    },
    components: {
        TheToolbar,
        GlobalDialog
    },
    errorCaptured(error, vm, info) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/
        if (error?.message && chunkFailedMessage.test(error.message)) {
            this.forcePWAUpdate = true
        }
        return false
    }
}
</script>

<style lang="scss">
@import '@/sass/nuvolos.scss';
</style>

<style scoped>
.snackbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.intercom-padding {
    padding-bottom: 98px !important;
}
</style>
