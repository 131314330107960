import $axios from '@/backend.js'
import { orderBy, get } from 'lodash'
const { getInstance } = require(`@/auth/${process.env.VUE_APP_AUTH_PLUGIN}`)

const userStore = {
    namespaced: true,
    state: {
        sshKeys: [],
        snowflakeTokens: [],
        fetchingSSHKeys: false,
        isAccountManager: false,
        userTasks: [],
        fetchingAvailableResourcePools: false,
        availableResourcePools: [],
        defaultResourcePool: {},
        userRequests: { own: [], toApprove: [] },
        fetchingUserTasks: false,
        totalTasks: 0,
        runningTasks: 0,
        maxTaskPerPage: 0,
        fetchingUserOwnRequests: false,
        fetchingUserToApproveRequests: false,
        userTaskPollingInterval: null,
        selectedResourcePool: null,
        selectedResourcePoolHpcUsers: [],
        fetchingResourceDescription: false,
        fetchingResourceBalanceCurrentMonth: false,
        fetchingResourceBalance: false,
        fetchingResourceBalanceBreakdown: false,
        fetchingResourcePoolResources: false,
        fetchingResourcePoolUsers: false,
        fetchingResourcePoolHpcUsers: false,
        fetchingDefaultResourcePool: false,
        signupUserDetails: {}
    },
    mutations: {
        setSSHKeys: function (state, keys) {
            state.sshKeys = keys
        },
        setAccountManagerStatus(state, status) {
            state.isAccountManager = status
        },
        setSelectedResourcePool(state, newVal) {
            state.selectedResourcePool = Number(newVal)
        },
        setSelectedResourcePoolHpcUsers(state, newval) {
            state.selectedResourcePoolHpcUsers = newval
        },
        setFetchingSSHKeys(state, status) {
            state.fetchingSSHKeys = status
        },
        setResourcePoolUsersFetching(state, status) {
            state.fetchingResourcePoolUsers = status
        },
        setResourcePoolHpcUsersFetching(state, status) {
            state.fetchingResourcePoolHpcUsers = status
        },
        setResourcePoolResourcesFetching(state, status) {
            state.fetchingResourcePoolResources = status
        },
        setResourceDescriptionFetching(state, status) {
            state.fetchingResourceDescription = status
        },
        setResourceBalanceCurrentMonthFetching(state, status) {
            state.fetchingResourceBalanceCurrentMonth = status
        },
        setResourceBalanceFetching(state, status) {
            state.fetchingResourceBalance = status
        },
        setResourceBalanceBreakdownFetching(state, status) {
            state.fetchingResourceBalanceBreakdown = status
        },
        setSnowflakeTokens(state, tokens) {
            state.snowflakeTokens = tokens
        },
        setResourcePoolDataFetching(state, status) {
            state.fetchingAvailableResourcePools = status
        },
        setAvailableResourcePoolData(state, data) {
            state.availableResourcePools = orderBy(data, ['long_id'], ['asc'])
        },
        setResourcePoolFetching(state, status) {
            state.fetchingResourcePools = status
        },
        setDefaultResourcePool(state, data) {
            state.defaultResourcePool = data
        },
        setUserTasks: function (state, tasks) {
            state.userTasks = tasks
        },
        setUserOwnRequests: function (state, requests) {
            state.userRequests.own = requests
        },
        setUserToApproveRequests: function (state, requests) {
            state.userRequests.toApprove = requests
        },
        setFetchingUserTasks: function (state, status) {
            state.fetchingUserTasks = status
        },
        setTotalTasks: function (state, num) {
            state.totalTasks = num
        },
        setRunningTasks: function (state, num) {
            state.runningTasks = num
        },
        setMaxTaskPerPage: function (state, max) {
            state.maxTaskPerPage = max
        },
        setFetchingUserOwnRequests: function (state, status) {
            state.fetchingUserOwnRequests = status
        },
        setFetchingUserToApproveRequests: function (state, status) {
            state.fetchingUserToApproveRequests = status
        },
        setUserTaskPollingInterval: function (state, interval) {
            state.userTaskPollingInterval = interval
        },
        setSignupUserDetails: function (state, details) {
            state.signupUserDetails = details
        }
    },
    actions: {
        fetchSSHKeys: function (context) {
            context.commit('setFetchingSSHKeys', true)
            $axios
                .get('tokens/ssh_keys')
                .then(response => {
                    context.commit('setSSHKeys', response.data)
                })
                .finally(() => {
                    context.commit('setFetchingSSHKeys', false)
                })
        },
        setResourcePoolUsersFetching: function (context, newVal) {
            context.commit('setResourcePoolUsersFetching', newVal)
        },
        setResourcePoolResourcesFetching: function (context, newVal) {
            context.commit('setResourcePoolResourcesFetching', newVal)
        },
        setResourceDescriptionFetching: function (context, newVal) {
            context.commit('setResourceDescriptionFetching', newVal)
        },
        setResourceBalanceCurrentMonthFetching: function (context, newVal) {
            context.commit('setResourceBalanceCurrentMonthFetching', newVal)
        },
        setResourceBalanceFetching: function (context, newVal) {
            context.commit('setResourceBalanceFetching', newVal)
        },
        setResourceBalanceBreakdownFetching: function (context, newVal) {
            context.commit('setResourceBalanceBreakdownFetching', newVal)
        },
        setSelectedResourcePool: function (context, newVal) {
            context.commit('setSelectedResourcePool', newVal)
        },
        setSignupUserDetails: function (context, newVal) {
            context.commit('setSignupUserDetails', newVal)
        },
        fetchAvailableResourcePoolData: function (context) {
            context.commit('setResourcePoolDataFetching', true)
            $axios
                .get(`/resource/available_rps`)
                .then(response => {
                    context.commit('setAvailableResourcePoolData', response.data)
                    context.commit('setSelectedResourcePool', get(response, 'data[0].rpid'))
                })
                .catch(() => {
                    context.commit(
                        'showSnackBar',
                        {
                            snackBarText: 'Failed to fetch available resource pools.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        },
                        { root: true }
                    )
                })
                .finally(() => {
                    context.commit('setResourcePoolDataFetching', false)
                })
        },
        async fetchDefaultResourcePool(context, oid) {
            // TODO we should have a fetching loader here to be used on UI somewhere
            // context.commit('setFetchingDefaultResourcePool', true)
            try {
                const { data } = await $axios.get(`/orgs/${oid}/default_rp`)
                context.commit('setDefaultResourcePool', data)
            } catch (error) {}
            // TODO we should have a fetching loader here to be used on UI somewhere
            // context.commit('setFetchingDefaultResourcePool', false)
        },
        fetchSnowflakeTokens: function (context) {
            $axios.get('tokens/snowflake_access').then(response => {
                context.commit('setSnowflakeTokens', response.data)
            })
        },
        fetchAccountManagerStatus: function (context) {
            $axios.get('/is_account_manager').then(response => {
                context.commit('setAccountManagerStatus', response.data)
            })
        },
        fetchResourcePoolHpcUsers: function (context, rpid) {
            context.commit('setResourcePoolHpcUsersFetching', true)
            $axios
                .get(`/resource/${rpid}/list_users`)
                .then(response => {
                    context.commit('setSelectedResourcePoolHpcUsers', response.data)
                })
                .catch(() => {
                    context.commit(
                        'showSnackBar',
                        {
                            snackBarText: 'Failed to fetch organization users.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        },
                        { root: true }
                    )
                })
                .finally(() => {
                    context.commit('setResourcePoolHpcUsersFetching', false)
                })
        },
        fetchUserTasks: async function (context, params) {
            // itt kell az auth? Sztem nem....
            if (getInstance().isAuthenticated) {
                context.commit('setFetchingUserTasks', true)
                try {
                    const metadata = await $axios.get(`/tasks/list?${new URLSearchParams(params)}&metadata=true`)
                    context.commit('setTotalTasks', metadata.data.count)
                    context.commit('setMaxTaskPerPage', metadata.data.max_per_page)
                    const tasks = await $axios.get(`/tasks/list?${new URLSearchParams(params)}`)
                    context.commit('setUserTasks', tasks.data)
                } catch (error) {
                    context.commit(
                        'showSnackBar',
                        {
                            snackBarText: 'Failed to fetch user tasks.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        },
                        { root: true }
                    )
                } finally {
                    context.commit('setFetchingUserTasks', false)
                }
            }
        },
        async fetchRunningUserTasks(context) {
            try {
                const { data } = await $axios.get(`/tasks/list?status=3&metadata=true`)
                context.commit('setRunningTasks', data.count)
            } catch (error) {
                context.commit('setRunningTasks', error)
            }
        },
        fetchUserOwnRequests: function (context) {
            if (getInstance().isAuthenticated) {
                context.commit('setFetchingUserOwnRequests', true)
                $axios
                    .get('users/my_requests?valid=both')
                    .then(response => {
                        context.commit('setUserOwnRequests', response.data)
                    })
                    .finally(() => {
                        context.commit('setFetchingUserOwnRequests', false)
                    })
            }
        },
        fetchUserToApproveRequests: function (context) {
            if (getInstance().isAuthenticated) {
                context.commit('setFetchingUserToApproveRequests', true)
                $axios
                    .get('users/requests_to_review?valid=both')
                    .then(response => {
                        context.commit('setUserToApproveRequests', response.data)
                    })
                    .finally(() => {
                        context.commit('setFetchingUserToApproveRequests', false)
                    })
            }
        },
        setUserTaskPollingInterval: function (context) {
            context.commit(
                'setUserTaskPollingInterval',
                setInterval(() => {
                    context.dispatch('fetchUserTasks')
                }, 120000)
            )
        }
    },
    getters: {
        validRequests: state => {
            if (state.userRequests.toApprove.length) {
                return state.userRequests.toApprove.filter(request => request.valid === 'True')
            }
            return []
        },
        isResourcePoolManager: state => {
            if (state.availableResourcePools.length && state.selectedResourcePool) {
                return state.availableResourcePools.find(pool => pool.rpid.toString() === state.selectedResourcePool.toString()).is_rp_manager
            }
            return false
        }
    }
}

export default userStore
