import Vue from 'vue'
import Router from 'vue-router'
import homeRoutes from './modules/home/routes'
import snapshotRoutes from './modules/snapshot/routes'
import invitationRoutes from './modules/invitations/routes'
import userRoutes from './modules/user/routes'
import adminRoutes from './modules/admin/routes'
import { publicNames } from './utils'
const TheLoginPage = () => import('./views/TheLoginPage.vue')
const FourOFour = () => import('@/views/The404')
const TheLoginLandingPage = () => import('@/views/TheLoginLandingPage.vue')
const { getInstance } = require(`@/auth/${process.env.VUE_APP_AUTH_PLUGIN}`)

Vue.use(Router)

const baseRoutes = [
    {
        path: '/',
        name: 'welcome',
        components: {
            default: TheLoginLandingPage
        }
    },
    {
        path: '/login',
        name: 'login',
        components: {
            default: TheLoginPage
        }
    },
    { path: '/welcome', redirect: '/' }
]

const routes = baseRoutes
    .concat(homeRoutes)
    .concat(snapshotRoutes)
    .concat(invitationRoutes)
    .concat(userRoutes)
    .concat(adminRoutes)
    .concat([
        {
            path: '*',
            name: 'notfound',
            component: FourOFour
        }
    ])

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // allow access to root, callback and invitaitons, otherwise require user being authenticated
    if (publicNames.indexOf(to.name) > -1 || getInstance().isAuthenticated) {
        return next()
    }
    router.push({ name: 'login', query: { ...to.query, targetUrl: to.fullPath } })
})

export default router
