import { mapState } from 'vuex'
import LogRocket from 'logrocket'
import { randomString } from '@/utils'

export const analyticsMixin = {
    computed: {
        ...mapState(['userMetadata']),
        isAnalyticsEnabled() {
            const userPreference = this.userMetadata?.analytics_opt_in || !('analytics_opt_in' in this.userMetadata)
            const envConstraint = process.env.NODE_ENV === 'production'
            const override = window.localStorage.getItem('nvLogRocket') !== null

            return (userPreference && envConstraint) || override
        }
    },
    methods: {
        cleanUpData(data) {
            for (const key in data) {
                if (Array.isArray(data[key]) && data[key].length === 0) data[key] = 'None'
            }
            return data
        },
        sendAnalyticsEvent(eventName, eventData) {
            if (!this.isAnalyticsEnabled) return
            this.cleanUpData(eventData)
            LogRocket.track(eventName, eventData)
        },
        sendErrorEvent(eventName, eventData) {
            LogRocket.captureMessage(`${eventName} [${randomString(8)}]`, {
                extra: this.cleanUpData(eventData)
            })
        }
    }
}
